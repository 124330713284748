import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Navbar } from "../Navbar";
import style from "./style.module.scss";

const Layout = () => {
	const { pathname } = useLocation();
	const isDashboard = /^\/dashboard\/?$/.test(pathname);

	return (
		<div className={`${style.wrapper} ${isDashboard ? "dashboard" : ""}`}>
			<Navbar />
			<div className="container">
				<main className={style.main}>
					<Outlet />
				</main>
			</div>
		</div>
	);
};

export default Layout;
