import { yupResolver } from "@hookform/resolvers/yup";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "src/components/Button";
import { Input } from "src/components/Input";
import { Modal } from "src/components/Modal";
import { handleError } from "src/utils/api-error-handling";
import axios from "src/utils/axios";
import notify from "src/utils/toast";
import { translate } from "src/utils/translate";
import { number, object, string } from "yup";
import style from "./style.module.scss";

const schema = () =>
	object({
		insurance: number().required(translate.errors.required),
		medicines: number().required(translate.errors.required),
		medicines_full_name: string(),
		organization_price: number()
			.required(translate.errors.required)
			.min(0, "مبلغ نباید کمتر از ۰ باشد"),
		organization_percent: number()
			.required(translate.errors.required)
			.max(100, "مقدار نباید بیشتر از ۱۰۰ باشد")
			.min(0, "مقدار نباید کمتر از ۰ باشد"),
	});

const sxProps = {
	fontFamily: "inherit",
	boxShadow: "none",
	borderRadius: "0.75rem",
	border: "1px solid var(--bl-on-surface-38)",
	backgroundColor: "var(--bl-surface-container-lowest)",
	"&.MuiFilledInput-root": {
		borderColor: "transparent",
		color: "var(--bl-on-surface)",
		backgroundColor: "var(--bl-surface-container-low)",
	},
	"& .MuiInputBase-input": {
		position: "relative",
		fontSize: "0.75rem",
		height: "1rem",
		lineHeight: "1rem",
		padding: "0.25rem 0.75rem 0.25rem !important",
		minHeight: "unset",
		"&:focus": {
			boxShadow: "none",
			backgroundColor: "transparent",
		},
	},
};

const MedicineModal = ({
	open,
	setOpen,
	reload,
	setReload,
	setDefaultValue,
	insuranceID,
	defaultValue = null,
}) => {
	const inputRefName = useRef();

	const {
		register,
		setError,
		setValue,
		reset,
		getValues,
		watch,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		resolver: yupResolver(schema()),
	});

	const [loading, setLoading] = useState(false);
	const [editItemID, setEditItemID] = useState(null);
	const [medicineNameOptions, setMedicineNameOptions] = useState([]);
	const full_name_medicine = watch("medicines_full_name");

	const getMedicinesData = () => {
		setLoading(true);
		axios
			.get("user/medicine/list/all/")
			.then((res) => {
				const nameOptions = [];
				res.data.map((item) => {
					nameOptions.push({
						label: `${item.full_name}`,
						value: item.id,
						key: item.id,
					});
				});
				setMedicineNameOptions(nameOptions);
			})
			.catch((err) => {
				handleError(err);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const onSubmit = (data) => {
		setLoading(true);
		delete data["medicines_full_name"];
		if (editItemID === null) {
			axios
				.post("/admin/insurance/medicines/manage/list_create/", data)
				.then((res) => {
					closeModal();
					notify("با موفقیت ثبت شد", "success");
					setReload(!reload);
				})
				.catch((err) => {
					handleError({ err, setError });
				})
				.finally(() => setLoading(false));
		} else {
			axios
				.put(`/admin/insurance/medicines/manage/update_delete/?pk=${editItemID}`, data)
				.then((res) => {
					closeModal();
					notify("با موفقیت ویرایش شد", "success");
					setReload(!reload);
				})
				.catch((err) => {
					handleError({ err, setError });
				})
				.finally(() => setLoading(false));
		}
	};
	const closeModal = () => {
		setOpen(false);
		reset();
		setDefaultValue(null);
		setEditItemID(null);
	};
	const addNewMedicineItem = (event, newValue) => {
		if (newValue) {
			setValue("medicines", newValue.value);
		}
	};

	useEffect(() => {
		if (defaultValue !== null) {
			setEditItemID(defaultValue?.id);
			setValue("medicines", defaultValue?.medicines);
			setValue("medicines_full_name", defaultValue?.medicines_full_name);
			setValue("organization_price", defaultValue?.organization_price);
			setValue("organization_percent", defaultValue?.organization_percent);
		}
	}, [defaultValue]);
	useEffect(() => {
		if (open) {
			setValue("insurance", insuranceID);
			if (medicineNameOptions.length === 0) {
				getMedicinesData();
			}
		}
	}, [open]);

	return (
		<Modal
			fullWidth
			state={open}
			setState={closeModal}
			maxWidth="md"
			footerEnd={
				<div className={style.buttons}>
					<Button size="xlarge" variant="ghost" onClick={closeModal}>
						انصراف
					</Button>
					<Button size="xlarge" onClick={handleSubmit(onSubmit)} loading={loading}>
						تایید
					</Button>
				</div>
			}
		>
			<form className={style.form}>
				<Autocomplete
					disablePortal
					fullWidth
					PopperComponent={(props) => (
						<Popper {...props} style={style.popper} placement="bottom-start" />
					)}
					id="medicine_name"
					size="small"
					options={medicineNameOptions}
					renderInput={(params) => (
						<TextField
							size="small"
							{...params}
							inputRef={inputRefName}
							sx={sxProps}
							error={errors?.medicines}
							helperText={errors?.medicines?.message}
							value={full_name_medicine}
						/>
					)}
					onChange={addNewMedicineItem}
					inputValue={full_name_medicine}
					value={full_name_medicine}
				/>
				<Input
					className={style.form__input}
					size="xlarge"
					label="قیمت بیمه"
					required
					error={errors.organization_price?.message}
					{...register("organization_price")}
				/>
				<Input
					className={style.form__input}
					size="xlarge"
					label="درصد سازمان"
					error={errors.organization_percent?.message}
					{...register("organization_percent")}
				/>
			</form>
		</Modal>
	);
};

export default MedicineModal;
