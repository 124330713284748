import { Autocomplete, Divider, Popper, TextField, Tooltip } from "@mui/material";
import _debounce from "lodash/debounce";
import { useEffect, useMemo, useRef, useState } from "react";
import iconInfo from "src/assets/icons/icon-info.svg";
import iconRemove from "src/assets/icons/icon-remove.svg";
import { handleError } from "src/utils/api-error-handling";
import axios from "src/utils/axios";
import style from "./style.module.scss";

const styles = (theme) => ({
	popper: {
		width: "fit-content",
	},
});

const sxProps = {
	fontFamily: "inherit",
	boxShadow: "none",
	borderRadius: "0.75rem",
	border: "1px solid var(--bl-on-surface-38)",
	backgroundColor: "var(--bl-surface-container-lowest)",
	"&.MuiFilledInput-root": {
		borderColor: "transparent",
		color: "var(--bl-on-surface)",
		backgroundColor: "var(--bl-surface-container-low)",
	},
	"& .MuiInputBase-input": {
		position: "relative",
		fontSize: "0.75rem",
		height: "1rem",
		lineHeight: "1rem",
		padding: "0.25rem 0.75rem 0.25rem !important",
		minHeight: "unset",
		"&:focus": {
			boxShadow: "none",
			backgroundColor: "transparent",
		},
	},
};

export const MedicineItem = ({ insurance }) => {
	const inputRefName = useRef();
	const inputRefNumber = useRef();

	const [loading, setLoading] = useState(false);
	const [medicineData, setMedicineData] = useState([]);
	const [medicineNameOptions, setMedicineNameOptions] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [calculatedMedicine, setCalculatedMedicine] = useState([]);
	const [tabIndexCount, setTabIndexCount] = useState(-1);

	const debouncedSearch = useMemo(
		() =>
			_debounce((value) => {
				setSearchValue(value);
			}, 300),
		[searchValue],
	);

	useEffect(() => {
		const handleTab = (event) => {
			if (event.keyCode === 9) {
				event.preventDefault();
				let counter = Number(
					document.getElementById("hidden_tabindex_counter").getAttribute("count"),
				);
				let tabIndex = counter + 1;
				if (tabIndex === 0) {
					tabIndex = -1;
					inputRefName.current.focus();
				}
				setTabIndexCount(tabIndex);
			}
		};
		document.addEventListener("keydown", handleTab);

		return () => {
			if (inputRefName.current) {
				inputRefName.current.focus();
			}
			document.removeEventListener("keydown", handleTab);
		};
	}, []);
	useEffect(() => {
		document.getElementById("hidden_tabindex_counter").setAttribute("count", tabIndexCount);
	}, [tabIndexCount]);
	useEffect(() => {
		setCalculatedMedicine([]);
		getData();
	}, [insurance]);
	useEffect(() => {
		if (inputRefNumber.current) {
			inputRefNumber.current.focus();
		}
	}, [calculatedMedicine]);
	useEffect(() => {
		getData();
	}, [searchValue]);

	const getData = () => {
		if (insurance !== null) {
			setLoading(true);
			axios
				.get(`user/medicine/list/`, {
					params: { search: searchValue, insurance_id: insurance, page: 1, page_size: 45 },
				})
				.then((res) => {
					setMedicineData(res.data?.results);
					const nameOptions = [];
					res.data?.results.map((item) => {
						nameOptions.push({
							label: `${item.full_name} - ${item.generic_code} - ${item.company}`,
							value: item.id,
							key: item.id,
						});
					});
					setMedicineNameOptions(nameOptions);
				})
				.catch((err) => {
					handleError(err);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};
	const addNewMedicineItem = (event, newValue) => {
		if (newValue) {
			const medicineId = newValue.value;
			const medicine = medicineData.find((item) => item.id === medicineId);
			const calculatedMedicineVar = [...calculatedMedicine];
			const newMedicine = { ...medicine };
			newMedicine.count = null;
			calculatedMedicineVar.push(newMedicine);
			setCalculatedMedicine(calculatedMedicineVar);
		}
	};
	const filterOptions = (options, { inputValue }) => {
		debouncedSearch(inputValue);
		return options;
	};
	const removeMedicineItem = (item) => {
		const calculatedMedicineVar = [...calculatedMedicine];
		const medicineIndex = calculatedMedicineVar.findIndex((node) => node.id === item.id);
		if (medicineIndex > -1) {
			calculatedMedicineVar.splice(medicineIndex, 1);
			setCalculatedMedicine([...calculatedMedicineVar]);
		}
	};
	const changeCountMedicineItem = (event, item, field_name) => {
		const calculatedMedicineVar = [...calculatedMedicine];
		const medicineIndex = calculatedMedicineVar.findIndex((node) => node.id === item.id);
		calculatedMedicineVar[medicineIndex][field_name] = Number(event.target.value.replace(",", ""));
		setCalculatedMedicine([...calculatedMedicineVar]);
	};

	return (
		<div className={style.wrapper}>
			<div id="hidden_tabindex_counter" stype={{ display: "none" }} count="0" />
			<div className={style.info}>
				<div className={style.info__headrow}>
					<div className={style.info__start}>
						<span>کد ژنریک</span>
						<span>کد irc</span>
						<span className={style.info__bigSpan}>نام دارو</span>
						<span className={style.miniSize}>تعداد</span>
						<span>قیمت واحد آزاد</span>
						<span>قیمت واحد بیمه</span>
						<span>اختلاف قیمت</span>
						<span>درصد سهم سازمان</span>
						<span>سهم سازمان</span>
						<span>سهم بیمار</span>
						<span>جمع قیمت</span>
					</div>
				</div>
				<Divider className={style.divider} />
				{calculatedMedicine.map((item, index) => (
					<div key={item.id} className={style.info__row}>
						<div className={style.info__columnStack}>
							<Tooltip title="پاک کردن">
								<img
									onClick={() => {
										removeMedicineItem(item);
									}}
									className={style.removeIcon}
									src={iconRemove}
									alt="icon-remove"
								/>
							</Tooltip>
							<Tooltip title={item?.term}>
								<img className={style.infoIcon} src={iconInfo} alt="icon-info" />
							</Tooltip>
						</div>
						<div className={style.info__start}>
							<span>{item.generic_code}</span>
							<span>{item.irc}</span>
							<span className={style.info__bigSpan}>
								{item.full_name} - {item.company}
							</span>
							<span className={style.miniSize}>
								<form
									onSubmit={(e) => {
										e.preventDefault();
										inputRefName.current.focus();
									}}
								>
									<TextField
										type="text"
										size="xsmall"
										value={item.count}
										inputRef={calculatedMedicine.length - 1 === index && inputRefNumber}
										onChange={(e) => changeCountMedicineItem(e, item, "count")}
										sx={sxProps}
									/>
								</form>
							</span>
							<span>
								<TextField
									type="text"
									size="xsmall"
									value={(item?.price).toLocaleString()}
									onChange={(e) => changeCountMedicineItem(e, item, "price")}
									sx={sxProps}
								/>
							</span>
							<span>
								{
									<TextField
										type="text"
										size="xsmall"
										value={(item?.organization_price).toLocaleString()}
										onChange={(e) => changeCountMedicineItem(e, item, "organization_price")}
										sx={sxProps}
									/>
								}
							</span>
							<span>{(item?.price - item?.organization_price).toLocaleString()}</span>
							<span>{Math.ceil(item?.organization_percent)}</span>
							<span>
								{Math.ceil(
									item?.organization_price * (item?.organization_percent / 100),
								).toLocaleString()}
							</span>
							<span>
								{Math.ceil(
									item?.organization_price * ((100 - item?.organization_percent) / 100),
								).toLocaleString()}
							</span>
							<span>
								{Math.ceil(
									(item?.price -
										item?.organization_price +
										item?.organization_price * ((100 - item?.organization_percent) / 100)) *
										item.count,
								).toLocaleString()}
							</span>
						</div>
					</div>
				))}
				<div className={style.info__row}>
					<div className={style.info__start}>
						<span>خالی</span>
						<span>خالی</span>
						<span className={style.info__bigSpan}>
							<Autocomplete
								disablePortal
								fullWidth
								PopperComponent={(props) => (
									<Popper {...props} style={styles.popper} placement="bottom-start" />
								)}
								filterOptions={filterOptions}
								id="medicine_name"
								size="small"
								options={medicineNameOptions}
								renderInput={(params) => (
									<TextField size="small" {...params} inputRef={inputRefName} sx={sxProps} />
								)}
								onChange={addNewMedicineItem}
								value={""}
							/>
						</span>
						<span className={style.miniSize}>0</span>
						<span>0</span>
						<span>0</span>
						<span>0</span>
						<span>0</span>
						<span>0</span>
						<span>0</span>
						<span>0</span>
					</div>
				</div>
				<Divider className={style.divider} />
				<div className={style.info__headrow}>
					<div className={style.info__start}>
						<span>
							جمع مبلغ کل آزاد:
							<br />
							{calculatedMedicine
								?.reduce((sum, item) => {
									return Math.ceil(sum + Math.ceil(item.count * item.price));
								}, 0)
								.toLocaleString()}
						</span>
						<Divider orientation="vertical" flexItem />
						<span>
							جمع سهم سازمان:
							<br />
							{calculatedMedicine
								?.reduce((sum, item) => {
									return (
										sum +
										(item?.organization_price
											? Math.ceil(
													item?.organization_price *
														(item?.organization_percent / 100) *
														item.count,
											  )
											: 0)
									);
								}, 0)
								.toLocaleString()}
						</span>
						<Divider orientation="vertical" flexItem />
						<span>
							جمع سهم بیمار:
							<br />
							{calculatedMedicine
								?.reduce((sum, item) => {
									return (
										sum +
										(item?.organization_price
											? Math.ceil(
													(item?.price -
														item?.organization_price +
														item?.organization_price * ((100 - item?.organization_percent) / 100)) *
														item.count,
											  )
											: Math.ceil(item.count * item.price))
									);
								}, 0)
								.toLocaleString()}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};
