import { IconButton, Tooltip } from "@mui/material";
import _debounce from "lodash/debounce";
import { useEffect, useMemo, useState } from "react";
import IconDelete from "src/assets/icons/icon-delete.svg";
import IconEdit from "src/assets/icons/icon-edit.svg";
import IconExcell from "src/assets/icons/icon-excel-file.svg";
import IconSearch from "src/assets/icons/icon-input-search.svg";
import IconAdd from "src/assets/icons/icon-plus-circle-success.svg";
import { Empty } from "src/components/Empty";
import { Input } from "src/components/Input";
import { Modal } from "src/components/Modal";
import { Table } from "src/components/Table";
import { handleError } from "src/utils/api-error-handling";
import axios from "src/utils/axios";
import ImportMedicineModal from "./ImportMedicineModal";
import MedicineModal from "./MedicineModal";
import style from "./style.module.scss";

const ListMedicinesModal = ({ open, setOpen, insuranceID }) => {
	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [paginationModel, setPaginationModel] = useState({ pageSize: 25, page: 0 });
	const [value, setValue] = useState("");
	const [reload, setReload] = useState(false);
	const [medicineModalOpen, setMedicineModalOpen] = useState(false);
	const [editMedicineData, setEditMedicineData] = useState(null);
	const [importMedicineModalOpen, setImportMedicineModalOpen] = useState(false);

	const debouncedSearch = useMemo(
		() =>
			_debounce((value) => {
				setSearchValue(value);
			}, 750),
		[searchValue],
	);

	const handleChange = (e) => {
		const value = e.target.value;
		setValue(value);
		debouncedSearch(value);
	};

	const getData = () => {
		setLoading(true);
		const { pageSize, page } = paginationModel;

		axios
			.get("/admin/insurance/medicines/manage/list_create/", {
				params: {
					insurance: insuranceID,
					search: searchValue,
					page: page + 1,
					page_size: pageSize,
				},
			})
			.then((res) => {
				setData(res.data.results);
				setCount(res?.data?.count_all);
			})
			.catch((err) => {
				handleError({ err });
			})
			.finally(() => setLoading(false));
	};
	const deleteInsurance = (id) => {
		axios
			.delete("/admin/insurance/medicines/manage/update_delete/", {
				params: { pk: id },
			})
			.then((res) => {
				getData();
			})
			.catch((err) => {
				handleError({ err });
			})
			.finally(() => setLoading(false));
	};
	const editInsurance = (item) => {
		setMedicineModalOpen(true);
		setEditMedicineData(item);
	};

	useEffect(() => {
		setPaginationModel((e) => ({ ...e, page: 0 }));
	}, [searchValue]);
	useEffect(() => {
		if (insuranceID) {
			getData();
		}
	}, [insuranceID, reload, paginationModel]);

	const columns = [
		{
			headerName: "کد IRC",
			field: "medicines_irc",
			flex: 1,
			sortable: false,
		},
		{
			headerName: "کد ژنریک",
			field: "medicines_generic_code",
			flex: 1,
			sortable: false,
		},
		{
			headerName: "نام",
			field: "medicines_full_name",
			flex: 1,
			sortable: false,
		},
		{
			headerName: "قیمت واحد(آزاد)",
			field: "medicines_price",
			flex: 1,
			sortable: false,
			renderCell: ({ row }) => row?.medicines_price.toLocaleString(),
		},
		{
			headerName: "قیمت بیمه",
			field: "organization_price",
			flex: 1,
			sortable: false,
			renderCell: ({ row }) => row?.organization_price.toLocaleString(),
		},
		{
			headerName: "درصد سازمان",
			field: "organization_percent",
			flex: 1,
			sortable: false,
		},
		{
			headerName: "",
			field: "action",
			sortable: false,
			renderCell: ({ row }) => (
				<div className={style.row}>
					<Tooltip title="ویرایش">
						<IconButton className={style.IconButton} onClick={() => editInsurance(row)}>
							<img src={IconEdit} alt="delete-icon" />
						</IconButton>
					</Tooltip>
					<Tooltip title="حذف">
						<IconButton className={style.IconButton} onClick={() => deleteInsurance(row?.id)}>
							<img src={IconDelete} alt="delete-icon" />
						</IconButton>
					</Tooltip>
				</div>
			),
		},
	];

	return (
		<>
			<Modal fullWidth state={open} setState={() => setOpen(false)} maxWidth="lg">
				<div className={style.wrapper}>
					<div className={style.main}>
						<div className={`${style.history} ${"active"}`}>
							<div className={style.header}>
								<div className={style.header__title}>
									<div className={style.title}>دارو های تحت پوشش</div>
									<Tooltip title="اضافه کردن بیمه">
										<IconButton
											className={style.IconButton}
											onClick={() => setMedicineModalOpen(true)}
										>
											<img src={IconAdd} alt="add-icon" />
										</IconButton>
									</Tooltip>
									<Tooltip title="اضافه/ویرایش گروهی دارو">
										<IconButton
											className={style.IconButton}
											onClick={() => setImportMedicineModalOpen(true)}
										>
											<img src={IconExcell} className={style.IconExcell} alt="import-icon" />
										</IconButton>
									</Tooltip>
								</div>
								<Input
									size="small"
									value={value}
									className={style.input}
									onChange={handleChange}
									placeholder="جستجو..."
									rightIcon={<img src={IconSearch} alt="search-icon" />}
								/>
							</div>
							{data.length > 0 ? (
								<div className={style.table}>
									<Table
										rows={data}
										rowCount={count}
										loading={loading}
										columns={columns}
										paginationMode="server"
										paginationModel={paginationModel}
										onPaginationModelChange={setPaginationModel}
									/>
								</div>
							) : (
								<Empty />
							)}
						</div>
					</div>
				</div>
			</Modal>
			<MedicineModal
				open={medicineModalOpen}
				setOpen={setMedicineModalOpen}
				setDefaultValue={setEditMedicineData}
				defaultValue={editMedicineData}
				reload={reload}
				setReload={setReload}
				insuranceID={insuranceID}
			/>
			<ImportMedicineModal
				open={importMedicineModalOpen}
				setOpen={setImportMedicineModalOpen}
				reload={reload}
				setReload={setReload}
				insuranceID={insuranceID}
			/>
		</>
	);
};

export default ListMedicinesModal;
