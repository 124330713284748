import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import IconInfo from "src/assets/icons/icon-info.svg";
import { Button } from "src/components/Button";
import { Modal } from "src/components/Modal";
import axios from "src/utils/axios";
import notify from "src/utils/toast";
import style from "./style.module.scss";

const ImportMedicineModal = ({ open, setOpen, reload, setReload, insuranceID }) => {
	const [loading, setLoading] = useState(false);
	const [excellFile, setExcellFile] = useState(null);
	const [fileError, setFileError] = useState(null);

	const handleSubmit = () => {
		setLoading(true);
		let newData = new FormData();
		newData.append("data_file", excellFile);
		newData.append("insurance", insuranceID);

		axios
			.post(`/admin/insurance/medicines/manage/import/`, newData)
			.then((res) => {
				closeModal();
				notify("داده های شما در حال درون ریزی میباشد، پس از چند دقیقه صفحه را مجدد بارگزاری کنید", "success");
				setReload(!reload);
			})
			.catch((err) => {
				const response = err?.response?.data ?? {};

				if (Object.hasOwn(response, "detail") || Object.hasOwn(response, "non_field_errors")) {
					notify(response.detail, "error");
					notify(response?.non_field_errors?.[0], "error");
				} else {
					for (let key in response) {
						setFileError(response[key][0]);
					}
				}
			})
			.finally(() => setLoading(false));
	};
	const closeModal = () => {
		setOpen(false);
		setExcellFile(null);
		setFileError(null);
	};

	useEffect(() => {
		setFileError(null);
	}, [excellFile]);

	return (
		<Modal
			fullWidth
			state={open}
			setState={closeModal}
			maxWidth="md"
			footerEnd={
				<div className={style.buttons}>
					<Button size="xlarge" variant="ghost" onClick={closeModal}>
						انصراف
					</Button>
					<Button size="xlarge" onClick={handleSubmit} loading={loading}>
						تایید
					</Button>
				</div>
			}
		>
			<form className={style.form}>
				<Typography fontSize="small" className={style.form__inputFull}>
					<img src={IconInfo} alt="info" /> فرمت های قابل تایید برای فایل: xlsx, csv
				</Typography>
				<Button className={style.form__inputFull}>
					{excellFile?.name ? excellFile?.name : "انتخاب فایل"}
					<input
						className={style.fileUploadInput}
						type="file"
						onChange={(event) => setExcellFile(event.target.files[0])}
					/>
				</Button>
				{fileError && (
					<Typography className={style.form__inputFull} color="error">
						{fileError}
					</Typography>
				)}
				نکته: اگر دارویی از قبل وجود داشته باشد و در اکسل موجود باشد، داده های آن بروزرسانی میشود.
			</form>
		</Modal>
	);
};

export default ImportMedicineModal;
