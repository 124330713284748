import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IconBack from "src/assets/icons/icon-arrows-right-black.svg";
import IconCalculate from "src/assets/icons/icon-calculate.svg";
import { handleError } from "src/utils/api-error-handling";
import axios from "src/utils/axios";
import { MedicineItem } from "./components/MedicineItem";
import style from "./style.module.scss";

const Medicine = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [insuranceData, setInsuranceData] = useState([]);
	const [selectedInsurance, setSelectedInsurance] = useState(null);

	useEffect(() => {
		setLoading(true);
		axios
			.get("user/insurance/list/all/")
			.then((res) => {
				setInsuranceData(res.data);
				setSelectedInsurance(res?.data?.[0]?.id);
			})
			.catch((err) => {
				handleError(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	return (
		<>
			<div className={style.wrapper}>
				<img
					className={style.back}
					src={IconBack}
					onClick={() => navigate("../")}
					alt="back-icon"
				/>
				<div className={style.header}>دارو ها</div>
				<div className={style.main}>
					<div className={style.section}>
						<div className={style.heading}>
							<span className={style.heading__title}>
								<img src={IconCalculate} alt="user-icon" />
								ماشین حساب دارو
							</span>
							<FormControl>
								<RadioGroup
									value={selectedInsurance}
									onChange={(event) => setSelectedInsurance(event.target.value)}
									className={style.insuranceList}
								>
									{insuranceData?.map((node, index) => (
										<FormControlLabel
											key={index}
											value={node?.id}
											control={<Radio />}
											label={node?.name}
										/>
									))}
								</RadioGroup>
							</FormControl>
						</div>

						<div className={style.information}>
							<MedicineItem insurance={selectedInsurance} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Medicine;
