export const translate = {
	errors: {
		required: "این فیلد الزامی است.",
		phone: "شماره همراه معتبر نیست.",
		otp: "کد تایید را درست وارد کنید.",
	},
	notify: {
		entranceSuccess: "ورود باموفقیت انجام شد.",
		sendOtpSucceed: "کد اعتبار سنجی برای شما ارسال شد",
	},
};
